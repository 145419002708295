import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import RequestStatus from '../data/models/RequestStatus';
import { authorize, fetchAppDetails, fetchApplicationAuthorizationData, fetchIsRequestingNewScopes, fetchInternallyDevelopedApps, noPortalFetchApplicationAuthorizationData, setAuthorizationSource } from '../actions/AppActions';
export const initialState = {
  authorizationDataStatus: RequestStatus.uninitialized,
  authorizeStatus: RequestStatus.uninitialized,
  applicationAuthorizationData: null,
  applicationAuthorizationErrorCode: null,
  appDetails: null,
  appDetailsStatus: RequestStatus.uninitialized,
  internallyDevelopedAppIds: [],
  internallyDevelopedStatus: RequestStatus.uninitialized,
  isRequestingNewScopesStatus: RequestStatus.uninitialized,
  isRequestingNewScopes: null,
  authorizationSource: null
};
const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {},
  extraReducers: builder => builder.addCase(fetchAppDetails.pending, state => {
    state.appDetailsStatus = RequestStatus.pending;
  }).addCase(fetchAppDetails.rejected, state => {
    state.appDetailsStatus = RequestStatus.failed;
  }).addCase(fetchAppDetails.fulfilled, (state, {
    payload
  }) => {
    state.appDetailsStatus = RequestStatus.succeeded;
    state.appDetails = payload;
  }).addCase(authorize.pending, state => {
    state.authorizeStatus = RequestStatus.pending;
  }).addCase(authorize.rejected, state => {
    state.authorizeStatus = RequestStatus.failed;
  }).addCase(authorize.fulfilled, state => {
    state.authorizeStatus = RequestStatus.succeeded;
    state.authorizationSource = null;
  }).addCase(fetchInternallyDevelopedApps.pending, state => {
    state.internallyDevelopedStatus = RequestStatus.pending;
  }).addCase(fetchInternallyDevelopedApps.rejected, state => {
    state.internallyDevelopedStatus = RequestStatus.failed;
  }).addCase(fetchInternallyDevelopedApps.fulfilled, (state, {
    payload
  }) => {
    state.internallyDevelopedStatus = RequestStatus.succeeded;
    state.internallyDevelopedAppIds = payload;
  }).addCase(fetchIsRequestingNewScopes.fulfilled, (state, {
    payload
  }) => {
    state.isRequestingNewScopes = payload;
    state.isRequestingNewScopesStatus = RequestStatus.succeeded;
  }).addCase(fetchIsRequestingNewScopes.rejected, state => {
    state.isRequestingNewScopesStatus = RequestStatus.failed;
  }).addCase(setAuthorizationSource, (state, {
    payload
  }) => {
    state.authorizationSource = payload;
  }).addMatcher(isAnyOf(fetchApplicationAuthorizationData.pending, noPortalFetchApplicationAuthorizationData.pending), state => {
    state.authorizationDataStatus = RequestStatus.pending;
  }).addMatcher(isAnyOf(fetchApplicationAuthorizationData.rejected, noPortalFetchApplicationAuthorizationData.rejected), (state, {
    payload
  }) => {
    state.applicationAuthorizationErrorCode = payload.errorCode;
    state.applicationAuthorizationErrorSubCategory = payload.subCategory;
    state.applicationAuthorizationErrorContext = payload.context;
    // Handles both error and warning states
    state.authorizationDataStatus = payload.status;
  }).addMatcher(isAnyOf(fetchApplicationAuthorizationData.fulfilled, noPortalFetchApplicationAuthorizationData.fulfilled), (state, {
    payload
  }) => {
    state.applicationAuthorizationData = payload;
    state.authorizationDataStatus = RequestStatus.succeeded;
  })
});
export default appsSlice.reducer;