import { createSelector } from 'reselect';
const getAppsState = state => state.app;
export const getAuthorizationDataStatus = createSelector([getAppsState], state => state.authorizationDataStatus);
export const getApplicationAuthorizationData = createSelector([getAppsState], state => state.applicationAuthorizationData);
// @ts-expect-error Replace type of state once root reducer is typed
export const getIsImpersonated = state => !!state.auth.user.acting_user_id;
export const getAuthorizeApplicationErrorCode = createSelector([getAppsState], state => state.applicationAuthorizationErrorCode);
export const getAuthorizationSource = createSelector([getAppsState], state => state.authorizationSource);
export const getAuthorizeApplicationErrorSubCategory = createSelector([getAppsState], state => state.applicationAuthorizationErrorSubCategory);
export const getAuthorizeApplicationErrorContext = createSelector([getAppsState], state => state.applicationAuthorizationErrorContext);
export const getAuthorizeStatus = createSelector([getAppsState], state => state.authorizeStatus);
export const getAppDetails = createSelector([getAppsState], state => state.appDetails);
export const getAppDetailsStatus = createSelector([getAppsState], state => state.appDetailsStatus);
export const getInternallyDevelopedAppIds = createSelector([getAppsState], state => state.internallyDevelopedAppIds);
export const getInternallyDevelopedAppIdsStatus = createSelector([getAppsState], state => state.internallyDevelopedStatus);
export const getIsRequestingNewScopesStatus = createSelector([getAppsState], state => state.isRequestingNewScopesStatus);
export const getIsRequestingNewScopes = createSelector([getAppsState], state => state.isRequestingNewScopes);
export const getIsInternallyDeveloped = createSelector([getApplicationAuthorizationData, getInternallyDevelopedAppIds, getInternallyDevelopedAppIdsStatus], (authData, ids, idsStatus) => {
  // The internallyDevelopedApps fetch isn't kicked off until after the
  // authorizationData fetch succeeds, so no need to check that status here.
  return idsStatus.succeeded && ids.includes(authData.appId);
});