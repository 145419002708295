import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import Raven from 'raven-js';
import enviro from 'enviro';
function isDebug() {
  return enviro.debug('oauth-ui') && (enviro.isQa('oauth-ui') || !enviro.deployed('oauth-ui'));
}
const sendExceptionsToSentry = () => next => action => {
  try {
    return next(action);
  } catch (err) {
    Raven.captureException(err, {
      extra: {
        action
      }
    });
    if (isDebug()) {
      console.error(err, {
        extra: {
          action
        }
      });
    }
    throw err;
  }
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk, sendExceptionsToSentry))(createStore);
export default function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
}